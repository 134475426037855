// ACTIONS
export const SELECT_FLAT = 'SELECT_FLAT';
export const SELECT_BLOCK = 'SELECT_BLOCK';
export const SET_IMG_LIST = 'SET_IMG_LIST';

// ACTION CREATORS
export const selectFlat = (selectedFlat, redirect) => ({
  type: SELECT_FLAT,
  payload: {
    flatTypeId: selectedFlat,
    redirect: redirect
  }
});

export const selectBlock = selectedBlock => ({
  type: SELECT_BLOCK,
  payload: selectedBlock
});

export const setImgList = list => ({
  type: SET_IMG_LIST,
  payload: list
});
