export default {
  flats: [
    {
      center: { x: 0.292, y: 0.163 },
      type: 0,
      wing: 'left',
      points: [
        [ 0.085, 0.025 ], [ 0.500, 0.025 ], [ 0.500, 0.300 ],
        [ 0.085, 0.300 ], [ 0.085, 0.025 ]
      ]
    },
    {
      center: { x: 0.708, y: 0.163 },
      type: 0,
      wing: 'right',
      points: [
        [ 0.500, 0.025 ], [ 0.915, 0.025 ], [ 0.915, 0.300 ],
        [ 0.500, 0.300 ], [ 0.500, 0.025 ]
      ]
    },
  ]
}
