export default {
  imgList: [
    '/gallery/1.jpg',
    '/gallery/2.jpg',
    '/gallery/3.jpg',
    '/gallery/4.jpg',
    '/gallery/8.jpg',
    '/gallery/9.jpg',
    '/gallery/10.jpg',
    '/gallery/11.jpg',
    '/gallery/12.jpg',
    '/gallery/13.jpg',
    '/gallery/14.jpg',
    '/gallery/15.jpg',
    '/gallery/16.jpg',
    '/gallery/17.jpg',
    '/gallery/18.jpg',
    '/gallery/19.jpg',
    '/gallery/20.jpg',
    '/gallery/21.jpg',
    '/gallery/22.jpg',
    '/gallery/23.jpg',
    '/gallery/24.jpg',
    '/gallery/25.jpg',
    '/gallery/26.jpg',
    '/gallery/27.jpg'
  ]
}
