export default {
  flatTypes: [
    {
      name: 'Экспликация 5-ти комнатной квартиры',
      type: '5-ти комнатная',
      totalSquare: 163.9,
      rooms: [
        { name: 'тамбур',  square: 5.7  },
        { name: 'холл',    square: 17.3 },
        { name: 'кухня',   square: 34.2 },
        { name: 'с/у',     square: 6.3  },
        { name: 'спальня', square: 14.3 },
        { name: 'холл',    square: 14.9 },
        { name: 'с/у',     square: 5.1  },
        { name: 'спальня', square: 18.9 },
        { name: 'спальня', square: 18.8 },
        { name: 'спальня', square: 14.1 },
        { name: 'спальня', square: 14.3 }
      ]
    },
    {
      name: 'Экспликация 3-х комнатной квартиры',
      type: '3-х комнатная',
      totalSquare: 132.1,
      rooms: [
        { name: 'тамбур',  square: 4.1  },
        { name: 'холл',    square: 19.2 },
        { name: 'кухня',   square: 26.5 },
        { name: 'с/у',     square: 3.8  },
        { name: 'спальня', square: 14.9 },
        { name: 'холл',    square: 7.9  },
        { name: 'с/у',     square: 6.3  },
        { name: 'спальня', square: 25   },
        { name: 'спальня', square: 25.4 }
      ]
    },
    {
      name: 'Экспликация 3-х комнатной квартиры',
      type: '3-х комнатная',
      totalSquare: 146.8,
      rooms: [
        { name: 'тамбур',  square: 4.1  },
        { name: 'холл',    square: 19.2 },
        { name: 'кухня',   square: 26.5 },
        { name: 'с/у',     square: 3.8  },
        { name: 'спальня', square: 13.9 },
        { name: 'холл',    square: 10.8 },
        { name: 'с/у',     square: 6.6  },
        { name: 'спальня', square: 31.8 },
        { name: 'спальня', square: 29.9 }
      ]
    },
    {
      name: 'Экспликация 5-ти комнатной квартиры',
      type: '5-ти комнатная',
      totalSquare: 163.9,
      rooms: [
        { name: 'тамбур',  square: 5.7  },
        { name: 'холл',    square: 17.3 },
        { name: 'кухня',   square: 34.2 },
        { name: 'с/у',     square: 6.3  },
        { name: 'спальня', square: 14.3 },
        { name: 'холл',    square: 14.9 },
        { name: 'с/у',     square: 5.1  },
        { name: 'спальня', square: 18.9 },
        { name: 'спальня', square: 18.8 },
        { name: 'спальня', square: 14.1 },
        { name: 'спальня', square: 14.3 }
      ]
    },
  ]
}