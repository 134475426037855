import flatsCoordinates from './flatsCoordinates';
import flatTypes from './flatTypes';
import flats from './flats';
import blocks from './blocks';
import gallery from './gallery';
import townhouses from './townhouses';

export default {
  selectedFlat: null,
  selectedBlock: 0,
  activeLanguage: 'ru',
  ...flatsCoordinates,
  ...flatTypes,
  ...blocks,
  ...flats,
  ...gallery,
  ...townhouses
};
