import {
  SELECT_BLOCK,
  SELECT_FLAT,
  SET_IMG_LIST
} from '../actions';

export default (state = {}, action) => {
  switch (action.type) {
    case SELECT_BLOCK:
      return {
        ...state,
        selectedBlock: action.payload
      };
    case SELECT_FLAT:
      return {
        ...state,
        selectedFlat: action.payload.flatTypeId
      };
    case SET_IMG_LIST:
      return {
        ...state,
        imgList: action.payload
      };
    default:
      return state;
  }
}
