export default {
  flatsCoordinates: [
    {
      center: { x: 0.252, y: 0.545 },
      flat: 'left',
      points: [
        [ 0.024, 0.170 ],
        [ 0.501, 0.170 ],
        [ 0.501, 0.920 ],
        [ 0.024, 0.920 ],
        [ 0.024, 0.170 ],
      ]
    },
    {
      center: { x: 0.742, y: 0.545 },
      flat: 'right',
      points: [
        [ 0.500, 0.170 ],
        [ 0.975, 0.170 ],
        [ 0.975, 0.920 ],
        [ 0.500, 0.920 ],
        [ 0.500, 0.170 ],
      ]
    }
  ]
}
