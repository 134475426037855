export default {
  thCoordinates: [
    // {
    //   center: { x: 0.056, y: 0.795 },
    //   type: 3,
    //   points: [
    //     [ 0.088, 0.695 ], [ 0.035, 0.695 ], [ 0.021, 0.803 ], [ 0.026, 0.898 ],
    //     [ 0.083, 0.898 ], [ 0.079, 0.803 ], [ 0.091, 0.693 ]
    //   ]
    // },
    {
      center: { x: 0.12, y: 0.782 },
      type: 3,
      points: [
        [ 0.101, 0.660 ], [ 0.160, 0.660 ], [ 0.147, 0.795 ], [ 0.151, 0.910 ],
        [ 0.093, 0.910 ], [ 0.081, 0.795 ], [ 0.101, 0.655 ]
      ]
    },
    {
      center: { x: 0.192, y: 0.782 },
      type: 3,
      points: [
        [ 0.164, 0.660 ], [ 0.231, 0.660 ], [ 0.218, 0.795 ], [ 0.223, 0.910 ],
        [ 0.157, 0.910 ], [ 0.153, 0.795 ], [ 0.165, 0.655 ]
      ]
    },
    {
      center: { x: 0.263, y: 0.795 },
      type: 2,
      points: [
        [ 0.230, 0.695 ], [ 0.305, 0.695 ], [ 0.294, 0.810 ], [ 0.297, 0.900 ],
        [ 0.227, 0.900 ], [ 0.220, 0.810 ], [ 0.230, 0.691 ]
      ]
    },
    {
      center: { x: 0.327, y: 0.84 },
      type: 1,
      points: [
        [ 0.300, 0.735 ], [ 0.355, 0.735 ], [ 0.349, 0.845 ], [ 0.355, 0.945 ],
        [ 0.303, 0.945 ], [ 0.298, 0.845 ], [ 0.303, 0.737 ]
      ]
    },
    {
      center: { x: 0.391, y: 0.839 },
      type: 2,
      points: [
        [ 0.359, 0.735 ], [ 0.430, 0.735 ], [ 0.427, 0.845 ], [ 0.430, 0.945 ],
        [ 0.359, 0.945 ], [ 0.353, 0.845 ], [ 0.359, 0.732 ]
      ]
    },
    // {
    //   center: { x: 0.471, y: 0.827 },
    //   type: 3,
    //   points: [
    //     [ 0.439, 0.710 ], [ 0.503, 0.710 ], [ 0.503, 0.945 ],
    //     [ 0.439, 0.945 ], [ 0.439, 0.708 ]
    //   ]
    // },
    // {
    //   center: { x: 0.537, y: 0.827 },
    //   type: 3,
    //   points: [
    //     [ 0.505, 0.710 ], [ 0.569, 0.710 ], [ 0.569, 0.945 ],
    //     [ 0.505, 0.945 ], [ 0.505, 0.708 ]
    //   ]
    // },
    {
      center: { x: 0.608, y: 0.833 },
      type: 2,
      points: [
        [ 0.574, 0.733 ], [ 0.639, 0.733 ], [ 0.644, 0.847 ], [ 0.640, 0.940 ],
        [ 0.574, 0.940 ], [ 0.575, 0.847 ], [ 0.572, 0.727 ]
      ]
    },
    {
      center: { x: 0.675, y: 0.833 },
      type: 1,
      points: [
        [ 0.644, 0.733 ], [ 0.699, 0.733 ], [ 0.707, 0.847 ], [ 0.703, 0.940 ],
        [ 0.646, 0.940 ], [ 0.650, 0.847 ], [ 0.644, 0.727 ]
      ]
    },
    // {
    //   center: { x: 0.74, y: 0.793 },
    //   type: 2,
    //   points: [
    //     [ 0.695, 0.685 ], [ 0.775, 0.685 ], [ 0.785, 0.805 ], [ 0.779, 0.904 ],
    //     [ 0.711, 0.904 ], [ 0.712, 0.805 ], [ 0.696, 0.682 ]
    //   ]
    // },
    {
      center: { x: 0.815, y: 0.782 },
      type: 3,
      points: [
        [ 0.775, 0.670 ], [ 0.841, 0.670 ], [ 0.854, 0.790 ], [ 0.850, 0.898 ],
        [ 0.787, 0.898 ], [ 0.792, 0.790 ], [ 0.776, 0.666 ]
      ]
    },
    {
      center: { x: 0.881, y: 0.782 },
      type: 3,
      points: [
        [ 0.845, 0.670 ], [ 0.905, 0.670 ], [ 0.917, 0.790 ], [ 0.913, 0.898 ],
        [ 0.855, 0.898 ], [ 0.860, 0.790 ], [ 0.845, 0.665 ]
      ]
    },
    {
      center: { x: 0.944, y: 0.795 },
      type: 1,
      points: [
        [ 0.912, 0.695 ], [ 0.965, 0.695 ], [ 0.979, 0.803 ], [ 0.974, 0.898 ],
        [ 0.917, 0.898 ], [ 0.921, 0.803 ], [ 0.909, 0.693 ]
      ]
    }
  ]
};
