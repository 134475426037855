// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contactus-js": () => import("./../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-flats-js": () => import("./../src/pages/flats.js" /* webpackChunkName: "component---src-pages-flats-js" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-not-available-js": () => import("./../src/pages/not_available.js" /* webpackChunkName: "component---src-pages-not-available-js" */),
  "component---src-pages-progress-js": () => import("./../src/pages/progress.js" /* webpackChunkName: "component---src-pages-progress-js" */),
  "component---src-pages-townhouses-js": () => import("./../src/pages/townhouses.js" /* webpackChunkName: "component---src-pages-townhouses-js" */)
}

